import { useEffect, useState } from "react";
import "./ChatList.css"
function ChatList(props) {
    let itemList = props.itemList;

    return (
        <div>
            {
                itemList.map((item, index) => (
                    <div key={index}>
                        <div className="question_container">
                            <img className="list_chatbot" src={process.env.PUBLIC_URL + '/user.png'} alt="Chatbot" />
                            <div className="list_content">{item.question}</div>
                        </div>

                        <div className="answer_container">
                            <img className="list_chatbot" src={process.env.PUBLIC_URL + '/chatbot.png'} alt="Chatbot" />
                            <div className="list_content">{item.answer}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default ChatList;