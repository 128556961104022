import { useLocation } from "react-router-dom";
import ChatList from "../../components/ChatList/ChatList";
import { getHistoryItemsForAppPage, getHistoryKey } from "../../utils/Utils";
import './HistoryPage.css'
import { useEffect, useState } from "react";

function HistoryPage() {
    const [historyItemsList, setHistoryItemsList] = useState([]);

    let location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    let app_page = searchParams.get('app_page')
    console.log("app_page", app_page);
    let historyKey = getHistoryKey(app_page);

    useEffect(() => {

        let itemList = getHistoryItemsForAppPage(app_page);

        setHistoryItemsList(itemList);
        setTimeout(() => {
            scrollToBottom();    
        }, 50);
        
    }, []);

    const onClickClearHistory = () => {
        let result = window.confirm("确定清除历史记录？");
        if (result) {
            console.log("确定");
            localStorage.removeItem(historyKey);
            sessionStorage.removeItem(historyKey);
            setHistoryItemsList([]);
        } else {
            console.log("取消");
        }
    }

    const scrollToBottom = ()=>{
        const bottomElement = document.getElementById('bottom_view');
        bottomElement.scrollIntoView({
            behavior:'instant',
            block:'end'
        });
    }

    return (
        <div className="container">
            <ChatList itemList={historyItemsList} ></ChatList>
            <div className='send_button'>
                <div onClick={onClickClearHistory} >清除历史记录</div>
            </div>
            <div id='bottom_view'></div>
        </div>
    );
}

export default HistoryPage;