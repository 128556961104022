
import './ChatView.css'
import React, { useEffect, useState } from 'react';
import getAnswer from '../../utils/ChatHelper'
import ChatList from '../../components/ChatList/ChatList';
import { getHistoryItemsForAppPageInSession, saveHistory, saveHistoryInSession } from '../../utils/Utils';
import { useNavigate } from 'react-router-dom';

function ChatView() {
  const [answer, setAnswer] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [inputHeight, setInputHeight] = useState('30px')
  const [itemList, setItemList] = useState([])
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  let theQuestion = "";
  let theFullAnswer = "";

  const textareaRef = React.createRef();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("chatview loaded");
    let aItemList = getHistoryItemsForAppPageInSession('smart_ask');
    setItemList(aItemList);

    setTimeout(() => {
      scrollToBottomInstantly();
    }, 100);

    return () => {
      console.log("chatview unloaded");
    };

  }, []);

  //监听inputValue，inputValue变化时更新textarea的高度
  useEffect(()=>{
    adjustTextAreaHeight();
    console.log("input value changed, hook");
  }, [inputValue])

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
    console.log("inputChange");    
  }

  const adjustTextAreaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight - 20 + "px";
    }
  }

  const handleKeyPress = (event) => {
    // if (event.key === 'Enter') {
    //   handleSubmit();
    // }
    if (event.keyCode === 13 && !event.shiftKey) {
      console.log("press enter");
      event.preventDefault(); //禁止默认的回车换行行为
      handleSubmit();
    }

    console.log('keycode:', event.keyCode);
  }

  const handleSubmit = async () => {
    if (isSendButtonDisabled) {
      return;
    }

    if (inputValue.trim() === "") {
      window.alert('输入内容不能为空');
      return;
    }

    theQuestion = inputValue;
    setInputValue("");

    setAnswer("loading...");

    let item = {
      question: inputValue,
      answer: "loading..."
    };

    let aItemList = itemList;
    aItemList.push(item);
    setItemList(aItemList);

    let messages = getMessagesWithQuestion(inputValue);
    console.log(messages);

    setTimeout(() => {
      scrollToBottom();
    }, 200);

    setIsSendButtonDisabled(true);

    getAnswer({
      messages: messages,
      onFullAnswer: (fullAnswer) => {
        aItemList.pop();

        let aItem = {
          question: inputValue,
          answer: fullAnswer
        };

        aItemList.push(aItem);
        setItemList([]);
        setItemList(aItemList);

        // console.log(aItemList);

        setAnswer(fullAnswer);
        theFullAnswer = fullAnswer;

        scrollToBottom();
      },
      onFinish: () => {
        setIsSendButtonDisabled(false);
        saveHistory(theQuestion, theFullAnswer, 'smart_ask');
        saveHistoryInSession(theQuestion, theFullAnswer, 'smart_ask');
      }
    });
  }

  const getMessagesWithQuestion = (question) => {
    let lastFiveItems = itemList.slice(-6);
    lastFiveItems.pop(); //移除掉“思考中”这条消息

    let messages = [];

    lastFiveItems.forEach((item) => {
      let questionMessage = {
        "role": "user",
        "content": item.question
      };
      let answerMessage = {
        "role": "assistant",
        "content": item.answer
      };
      messages.push(questionMessage);
      messages.push(answerMessage);
    });

    let questionMessage = {
      "role": "user",
      "content": question
    };
    messages.push(questionMessage);

    return messages;
  }

  const scrollToBottom = () => {
    const bottomElement = document.getElementById('bottom_view');
    bottomElement.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
  }

  const scrollToBottomInstantly = () => {
    const bottomElement = document.getElementById('bottom_view');
    bottomElement.scrollIntoView({
      behavior: 'instant',
      block: 'end'
    });
  }

  const onClickHistory = () => {
    navigate('/history?app_page=smart_ask');
  }

  return (
    <div className='container'>
      <div className='chatview_container'>
        <div className="answer_container">
          <img className="list_chatbot" src={process.env.PUBLIC_URL + '/chatbot.png'} alt="Chatbot" />
          <div className="list_content">欢迎使用A-WU AI</div>
        </div>
        <ChatList itemList={itemList}></ChatList>
        {/* hack：
      answer_placeholder是用于触发UI更新用的隐藏View，
      因为发现只更新itemList的话，ChatList不会更新。
       */}
        <div className='answer_placeholder'>{answer}</div>
      </div>
      <div className='chatview_bottom_view' id="bottom_view"></div>
      <div className='input_wrapper'>
        <img className='chatview_history' src={process.env.PUBLIC_URL + '/history-icon.png'} alt='history' onClick={onClickHistory} />
        <textarea ref={textareaRef} className='chatview_input' type="text" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyPress} ></textarea>
        <div className='chatview_send' onClick={handleSubmit}
          style={{ opacity: isSendButtonDisabled ? 0.5 : 1, pointerEvents: isSendButtonDisabled ? 'none' : 'auto' }}>
          <div>发送</div>
        </div>
      </div>
    </div>
  );
}

export default ChatView;
