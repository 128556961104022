import './App.css';
import AppCenter from './pages/AppCenter/AppCenter';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from 'react-router-dom';
import ChatView from './pages/ChatView/ChatView';
import AppDetailView from './pages/AppDetailView/AppDetailView';
import NavigationBar from './components/NavigationBar/NavigationBar';
import ChatList from './components/ChatList/ChatList';
import HistoryPage from './pages/HistoryPage/HistoryPage';
import SettingPage from './pages/SettingPage/SettingPage';
import PageFooter from './components/PageFooter/PageFooter';

const Home = () => <h2>Home Page</h2>;
const Setting = () => <h2>Setting Page</h2>;
const Contact = () => <h2>Contact Page</h2>;
const NotFound = () => <h2>404 Not Found</h2>;

function App() {
  return (
    <Router>
      {/* 路由配置 */}
      <div className='app-navigation-bar'>
        <NavigationBar></NavigationBar>
      </div>

      <Routes>
        <Route path="/" element={<AppCenter />} />
        <Route path="/setting" element={<SettingPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/chatview" element={<ChatView />} />
        <Route path="/appdetail" element={<AppDetailView />} />
        <Route path="/chatlist" element={<ChatList />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>      
    </Router>
  );
}

export default App;
