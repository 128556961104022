import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getApiKey } from './Utils';

function getAnswer(params = {
    messages: [],
    onMessage: () => { },
    onFullAnswer: (fullAnswer) => { },
    onFinish: () => { }
}) {
    const {
        messages,
        onMessage = () => { },
        onFullAnswer = () => { },
        onFinish = () => { }
    } = params;

    let api_key = getApiKey();
    // console.log('api key:', api_key);
    if (!api_key) {
        console.log('api key 为空');
        onFullAnswer("API KEY为空，请在'设置'页面输入API KEY");
        onFinish();

        return;
    }

    console.log('api key 不为空');

    let url = "https://service-iz51lcb6-1317139396.usw.tencentapigw.com/v1/chat/completions";

    let headers = {
        "Content-Type": "application/json",
        'Accept': 'text/event-stream',
        "Authorization": "Bearer " + api_key,
    }

    let model = "gpt-3.5-turbo-1106";

    let data = {
        "model": model,
        "messages": messages,
        // "messages": [{ "role": "user", "content": question }],
        "temperature": 0.5,
        "stream": true
    };

    const config = {
        method: 'post',
        url: url,
        data: JSON.stringify(data),
        headers: headers,
        responseType: 'stream'
    };

    let answer = ''

    const ctrl = new AbortController();
    fetchEventSource(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        signal: ctrl.signal,
        onopen(response) {
            // console.log("chat response:", response);
            // console.log("chat response data:", response.data);
            console.log("chat response.status:", response.status);

            if (response.status !== 200) {
                let text = `请求错误，错误码${response.status}`;
                onFullAnswer(text);
                onFinish();
            }
        },
        onmessage(msg) {
            let data = msg.data;
            let jsonStr = data.toString();
            // console.log(`msg type:${typeof msg}`);
            // console.log(`type:${typeof data}`);
            // console.log(jsonStr);

            if (jsonStr === '[DONE]') {
                console.log("finished");
                onFinish()
            } else {
                try {
                    let json = JSON.parse(jsonStr);
                    let content = json.choices[0].delta.content;
                    // console.log(jsonStr);
                    
                    if (content) {
                        console.log(`content:${content}`);
                        answer += content
                        onFullAnswer(answer)
                    }
                } catch (e) {
                    console.log('parse json error');
                }

            }
        },
        onclose() {

        },
        onerror(err) {
            console.log("error:", err);
        }
    });
}

export default getAnswer;
// module.exports = {
//     getAnswer
// }