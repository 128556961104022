
import './AppDetailView.css'
import { useRef, useState } from 'react';
import getAnswer from '../../utils/ChatHelper';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { getHistoryKey, saveHistory } from '../../utils/Utils';
import PageFooter from '../../components/PageFooter/PageFooter';

function AppDetailView() {
  const [answer, setAnswer] = useState('');

  //hack：theFullAnswer这个变量用来做本地存储，发现直接用state的变量answer存储时有时候这个值会不更新，一直是上一个值。
  let theFullAnswer = "";

  const [inputValue, setInputValue] = useState('')
  const [isResultVisible, setIsResultVisible] = useState(false)
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [sendButtonTitle, setSendButtonTitle] = useState('提交');
  const [copyButtonTitle, setCopyButtonTitle] = useState('复制');
  const textAreaRef = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dataString = searchParams.get('data');
  const item = JSON.parse(decodeURIComponent(dataString));

  // console.log(`item:${decodeURIComponent(dataString)}`);

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
    console.log(inputValue)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      //按“shift+enter”也可直接发送消息
      console.log("press shift + enter")
      event.preventDefault(); //阻止默认的换行行为
      event.target.blur();//让文本框失去焦点
      handleSubmit();
    } else if (event.key === 'Enter') {
      console.log("press enter");
    } else {

    }
  }

  const handleSubmit = async () => {
    if (isSendButtonDisabled) {
      return;
    }

    setInputValue(inputValue.trim());

    console.log("inputValue:", inputValue);
    if (inputValue.trim() === "") {
      window.alert("输入内容不能为空！");
      return;
    }

    setSendButtonTitle("生成中...");
    let question = item.prompt_command.replace("[command]", inputValue);
    let messages = [{ "role": "user", "content": question }];

    setIsSendButtonDisabled(true);

    getAnswer({
      messages: messages,
      onFullAnswer: (fullAnswer) => {
        setIsResultVisible(true)
        setAnswer(fullAnswer);
        theFullAnswer = fullAnswer;
        // console.log(`answer:${answer}`)
        scrollToBottom();
      },
      onFinish: () => {
        setSendButtonTitle("提交");
        setIsSendButtonDisabled(false);
        console.log(`full answer is:${theFullAnswer}`);

        saveHistory(inputValue, theFullAnswer, item.app_page);
      }
    })
  }

  const onClickClear = () => {
    setInputValue("");
    textAreaRef.current.focus();
  }

  const onClickHistory = () => {
    let historykey = getHistoryKey(item.app_page);
    console.log(historykey, item.app_page);
    // console.log(item);
    navigate(`/history?app_page=${item.app_page}`);
  }

  const onClickCopy = () => {
    const textToCopy = answer;
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);

    //显示“已复制”
    setCopyButtonTitle('已复制');

    setTimeout(() => {
      setCopyButtonTitle('复制');
    }, 2000);
  }

  const scrollToBottom = () => {
    const bottomElement = document.getElementById('bottom_view');
    bottomElement.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    })
  }

  return (
    <div className='container'>
      <div className='app_title'>{item.app_title}</div>
      <div className='app_description'>{item.app_description}</div>
      <div className='appdetail_textarea_wrapper'>
        <textarea className='appdetail_textarea' ref={textAreaRef} type="text" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyPress} ></textarea>
        <div className='appdetail_clear_button_wrapper'>
          <div className='appdetail_clear_button' onClick={onClickClear}>清除</div>
        </div>
      </div>
      <div className='send_button' onClick={handleSubmit}
        style={{ opacity: isSendButtonDisabled ? 0.5 : 1, pointerEvents: isSendButtonDisabled ? 'none' : 'auto' }}>
        <div>{sendButtonTitle}</div>
      </div>
      <div className='check_history' onClick={onClickHistory}>查看历史记录</div>

      {isResultVisible ? (
        <>
          <div className='result_title'>输出：</div>
          <div className='answer_wrapper'>
            <div className='answer'>{answer}</div>
          </div>
          <div className='send_button' onClick={onClickCopy}>
            <div>{copyButtonTitle}</div>
          </div>
          <div id='bottom_view'></div>
        </>
      ) : null}
      <PageFooter></PageFooter>
    </div>
  );
}

export default AppDetailView;
