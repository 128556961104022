import { useEffect, useState } from "react";
import './SettingPage.css'
import { getApiKey, setApiKey } from "../../utils/Utils";
import PageFooter from "../../components/PageFooter/PageFooter";

function SettingPage() {
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        let api_key = getApiKey();
        setInputValue(api_key);

        return () => {

        };
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    }

    const saveApiKey = () => {
        if (inputValue.trim() !== "") {
            setApiKey(inputValue);
            window.alert('API KEY已保存至本地');
        } else {
            window.alert('API KEY不能为空');
        }
    }

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            console.log("press enter");
            event.target.blur();//让文本框失去焦点
            saveApiKey();
        }
    }

    return (
        <div className="container">
            <div className="settingpage_title" >输入API KEY(openai)</div>
            <div className="settingpage_subtitle">API KEY仅会保存在本地</div>
            <input className="settingpage_input" type="password" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyPress}></input>
            <div className='send_button' onClick={saveApiKey}>
                <div>保存</div>
            </div>
            <PageFooter></PageFooter>
        </div>
    );
}

export default SettingPage;