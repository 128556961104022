
import React from 'react';
import {useNavigate } from 'react-router-dom';
import './NavigationBar.css'

function NavigationBar() {
    const navigate = useNavigate()
    const onClickLogo = ()=>{
        navigate('/');
    }

    const onClickAppCenter = ()=>{
        navigate('/');
    }

    const onClickSetting = ()=>{
        navigate('/setting');
    }

    return (
        <div className="list-container">
            <div className='navigationbar_logo_container' onClick={onClickLogo}>
            <img className='navigationbar_logo'  src={process.env.PUBLIC_URL + '/chatbot.png'} ></img>
            <div className='navigationbar_logo_title' >阿坞智能AI</div>
            </div>            
            
            <div className='navigationbar_title' onClick={onClickAppCenter}>应用中心</div>
            {/* <div><Link to="/contact">Contact</Link></div> */}
            <div className='navigationbar_title' onClick={onClickSetting}>设置</div>
        </div>
    )
}

export default NavigationBar



