import './PageFooter.css'

function PageFooter() {
    return (
        <div className='pagefooter_container'>
            <a className='pagefooter_icp' target='_blank' href='https://beian.miit.gov.cn/' rel="noopener noreferrer" >京ICP备2023007076号-1</a>
        </div>
    )
}

export default PageFooter;