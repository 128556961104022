import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from 'react-router-dom';
import './AppCenter.css'
import PageFooter from '../../components/PageFooter/PageFooter';

//读本地json文件
const categoryJson = require('../../assets/category_list.json')

const AppCenter = () => {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);

    const onTapItem = (item) => {
        console.log(`tap ${item.app_title}`);
        // console.log("tap item");
        if (item.app_page === "smart_ask") {
            navigate('/chatview');
        } else {
            const dataString = encodeURIComponent(JSON.stringify(item));
            navigate(`/appdetail?data=${dataString}`);
        }
    };

    useEffect(() => {
        console.log(categoryJson.category_list);
        setCategoryList(categoryJson.category_list);
    }, [])

    return (
        <div className='container'>
            <div>
                {
                    categoryList.map((item, index) => (
                        <div key={index}>
                            <div className="category-title">{item.category_title}</div>
                            <div className="app-list-container">
                                {
                                    item.app_list.map((item, index) => {
                                        return (
                                            <div key={index} className="item-container" onClick={() => onTapItem(item)}>
                                                <div className="app-title">{item.app_title}</div>
                                                <div className='app-description'>{item.app_description}</div>
                                                <br></br>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <PageFooter></PageFooter>
        </div>
    );
};


export default AppCenter;
