export function getHistoryKey(app_page) {
    return app_page + "_history_key";
}

export function saveHistory(question, answer, app_page) {
    const historyKey = getHistoryKey(app_page);
    let historyItemsStr = localStorage.getItem(historyKey);
    let historyItems = JSON.parse(historyItemsStr);
    if (!historyItems) {
      historyItems = []
    }

    const historyItem = {question:question, answer:answer};
    console.log(historyItem);
    historyItems.push(historyItem);
    
    localStorage.setItem(historyKey, JSON.stringify(historyItems));

    console.log("local:", localStorage.getItem(historyKey));
  }

  export function getHistoryItemsForAppPage(app_page) {
    let historyKey = getHistoryKey(app_page);
    let itemListStr = localStorage.getItem(historyKey);
    let itemList = JSON.parse(itemListStr);
    if (!itemList) {
        itemList = [];
    }

    return itemList;
  }

  export function saveHistoryInSession(question, answer, app_page) {
    const historyKey = getHistoryKey(app_page);
    let historyItemsStr = sessionStorage.getItem(historyKey);
    let historyItems = JSON.parse(historyItemsStr);
    if (!historyItems) {
      historyItems = []
    }

    const historyItem = {question:question, answer:answer};
    console.log(historyItem);
    historyItems.push(historyItem);
    
    sessionStorage.setItem(historyKey, JSON.stringify(historyItems));

    console.log("local:", localStorage.getItem(historyKey));
  }

  export function getHistoryItemsForAppPageInSession(app_page) {
    let historyKey = getHistoryKey(app_page);
    let itemListStr = sessionStorage.getItem(historyKey);
    let itemList = JSON.parse(itemListStr);
    if (!itemList) {
        itemList = [];
    }

    return itemList;
  }

  export function setApiKey(api_key) {
    if (api_key) {
        localStorage.setItem("api_key", api_key);
    }
  }

  export function getApiKey() {
    return localStorage.getItem('api_key');
  }

  export function isApiKeyEmpty() {
    let api_key = getApiKey();
    if(api_key.trim() !== "") {
        return true;
    } else {
        return false;
    }
  }